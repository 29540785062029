//Global

body {

    h1,h2,h3,h4,h5,h6 {
        font-family: $font-family-secondary;
        text-transform: uppercase;
    }
    &.homepage {

        .homeFeatures {
            display: none;
        }

  overflow-x: hidden;


        .carouselImageMultiple,
        .carousel {
            position: relative;
            &:before {
                content:"";
                background: linear-gradient(to bottom, #93EBEA, #FEDF77);
                display: block;
                // height: 100%;
                width: 100vw;
                position: absolute;
                bottom:0;
                left:50%;
                transform: translateX(-50%);
                height: calc(100% + 118px + 1em);
                z-index: -1;


                @media (max-width: 430px) {
                    height: calc(100% + 118px + 4em);  
                }
    
    
    
            }
        }
    }
}



//Button


.button {

    border:2px solid $body-colour;


    &:hover {
        background: $white;

        color:$body-colour;
        border:2px solid $body-colour;


        &:focus {
            border:2px solid #002BFF;
        }
    }


    &.inverse {
        background: $brand-primary;
        border:2px solid $brand-primary;

        &:hover {
            background: $custom-red;
            border:2px solid $custom-red;
            color:$white;
        }


    }
    &.tertiary-btn {
        background: $white;
        color:$body-colour;
        border:2px solid $body-colour;

        &:hover {
            background: $brand-secondary;
        }
    }


    &.tertiary-btn-arrow-small {
        background: $white;
        color:$body-colour;
        border:2px solid $body-colour;
        padding:10px 15px;


        &:after {
            content:"";
            background-image: url($assets-path + "arrow-right.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            display:inline-block;
            width: 16px;
            height:16px;
            margin-left: 0.5em;
      

        }

        &:hover {
            background: $brand-secondary;
        }
    }

    &.small-btn{
        padding: 10px 15px;
        background: $white;
        font-size: $font-size-small;
        color:$body-colour;
        border:2px solid $body-colour;

        &:hover {
            background: $brand-secondary;
        }

    }
}


//Header - Start

.menuAdminContainer {
    display: none;
}

.pageHeader  {
    position: relative;
    z-index: 2;

    .headerContent {
        position: relative;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 1em;

        @media (max-width:820px) {

        }

        @media (max-width:430px) {
            padding-top: calc(50px + 3rem);
        }
    }     




    .header-search {
        justify-content: flex-end;
        .searchContainer {
            position: relative;
            flex: 0 1 $header-search-input-max-width;
            input {
                border-bottom-width: 1px;

                &:focus {
                    outline:none;
                }
            }
            button {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                &:before {
                    font-weight: $font-weight-base;
                }
            }
        }
    }

    .menuMainAlt {
        font-size: 0;
        padding: 0;
        margin-left: 15px;
        background: none;
        border-radius: 0;
        border: none;
        width: 40px;
        height: 40px;
        &:hover,
        &.active {
            background: none;
        }
        &:before {
            font-size: 36px;
            width: auto;
            margin: 0;
            color: $black;
        }
    }

    .mainCallToAction {
        .buttonGethelp,
        .buttonDonate {
           &:hover {
            margin-left:0
           }

  
        }


        @media (max-width:430px) {

            .buttonGethelp {
                position: absolute;
        top: 0;
        left: 20px;
        width: calc(50% - 30px);
        margin-top: 1em;

            }

            .buttonDonate {
                position: absolute;
        top: 0;
        right: 20px;
        width: calc(50% - 30px);
        margin-top: 1em;

            }
}


        }


    }


    

    
    .mainLogo {
        position: absolute;
        left: 20px;
        top: 50%;

        @media (max-width:1024px) {
            top: 28%;
        }

        @media (max-width:430px) {
            top:53%;
        }
        
    }


#menuMain {
//   background:$brand-secondary;
@media (max-width:1024px) {
    
  background:$brand-secondary;




  @media (max-width:1024px) {
    
    background:$brand-secondary;
  
  
  
  
  .header-search {
      justify-content: start;
      .searchContainer  {
      
      flex:unset;
          input {
              margin: 0 1rem 0 0;
              max-width: calc(100% - 24px - 1rem);
              border-width: 0;
              padding: 0.375rem 0.75rem;
                  margin-left: 1em;
  }
          }
      }
  
  }
  


}
background: transparent;
    .topLevel {
        justify-content: end;

        li a {
            font-weight: $bold;
            color: $body-colour;
            background: none;
            font-size: 20px;

            &:after  {
                color:rgba($body-colour, 1)
            }
          

        }
        

       
    }


 
}
//Header - End





//Carousell- Main-banner  - Start

.homepage {
    // .carousel {

    // }

    .carouselImageMultiple {
        max-width: 100%;
        padding: 0;
        z-index: 1;
        .carouselControls {
            justify-content: flex-start;
            max-width: $container-max-width - 40px;
            margin: 0 auto;
            height:fit-content;
            top:unset;
            bottom: 10px;
            a {
                font-size: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $body-colour;

                &:hover {
                    background-color: $body-colour;

                    &.carouselControlPrev:before,&.carouselControlNext:before  {
                        filter: invert(1);

                    }
                }
                &:before {
                    line-height: 1;
                    height: 0px;
                    width: 0px;
                    position: relative;
                }
                &.carouselControlPrev:before {
                    background-image: url($assets-path + "AL-1.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 16px;
                    height:16px;
                    transform: rotate(180deg);
                }
                &.carouselControlNext:before {
            

                    background-image: url($assets-path + "AL-1.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 16px;
                    height:16px;

             

                }
            }
            @media (max-width: 768px) {
                bottom: 40px;
            }



        }
    }
    // #carouselSlides {
     
    // }
    #carouselSlides .carouselSlide {
        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100% - 60px);
        }
        .carouselSlideTitle {
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
            height:500px;
            top: 50%;
            bottom: auto;
            transform: translateY(-50%);
            padding-right:4em;

            @media (max-width:820px) {
                padding-right:2em;  
            }


            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: left;

                @media (max-width:820px) {
                    object-position: center;
                    margin-left: 1em;
                }
            
            }
        } 
        .carouselDetailWrapper {
            align-items: start;
            min-height: 698px;
            align-items: center;
            .carouselSlideDetail {
                padding: $carousel-details-padding;
                background: none;
                max-width: 50%;
            }
            .carouselSlideHeading {
                margin-bottom: 10px;
                text-transform: uppercase;
                font-size: 69px;

                @media (max-width:430px) {
                    font-size: 40px;
                }
                a:hover {
                    text-decoration: none;
                }
            } 
            .carouselSlideSummary {
                font-size: $font-size-large;
            }
            .carouselSlideReadMore {
                margin-top: 20px;
                border:2px solid $body-colour;

                &:hover {
                    background: $brand-secondary;
                    color: $body-colour;
                }

                @media (max-width:820px) {
                    width:100%
                }
            }
        }
        @media (max-width: 820px) {
            flex-wrap: wrap;
            &:before {
                height: 100%;
            }
            .carouselSlideTitle {
                flex: 100%;
                width: 100%;
                height: 340px;
                top: unset;
                right: unset;
                position: relative;
            }
            .carouselDetailWrapper {
                flex: 100%;
                min-height: auto;
                .carouselSlideDetail {
                    padding: 30px 20px 140px;
                    max-width: 100%;
                }
            }
        }
    }


    @media (max-width:820px) {
        #carouselSlides .carouselSlide {

            .carouselSlideTitle {
                transform: unset;
            }

        }
    }
}



//Carousell- Main-banner  - End


//Home box 1 - Section 1 - Start


#our-mission-heading {

 // padding:2em 0em;
 width: 100%;
 max-width: 1240px;
 margin-left: auto;
 margin-right: auto;
 // padding-top: 5rem;
 // padding-bottom: 5rem;
 padding: 2em 0em;
 padding-left: calc(20px - 15px);
 padding-right: calc(20px - 15px);
 position: relative;
 text-align: center;
 padding-top: 4em;



 .heading-wrapper {
    .desc {
        font-size: $font-size-large;
        font-weight: bold;

    }

    .title {
        font-size:57px;
        text-transform: uppercase;

        @media (max-width:820px) {
            font-size: 32px;
        }
    }
 }


 @media (max-width:820px) {
    // .title {
    //     font-size: 32px;
    // }
 }


}


.journeyoffthestreetsFeed {
    overflow: hidden;
    padding-top: 0;
    position: relative;
    .feedItemDetailsWrapper {
        display:flex;
        flex-direction: column;
        justify-content: center;
        padding: 2rem !important;
        background: #FFC38C !important;
        .button.tertiary-btn-arrow-small  {
            width: max-content !important

        }

        .feedTitle  {
            font-size:39px !important
        }

        @media (max-width: 1024px) {
            padding: 6rem 2rem !important;
        }


        @media (max-width: 430px) {
            padding: 2rem 2rem !important;
            padding-bottom: 6rem !important;
        }
    }


    .feedsTitle {
        display:none;
       
    }
    .feedList  {
        // max-width: 1585px;
        max-width: 80%;

        .feedItem {
            display: flex;

            @media (max-width:820px) {
                flex-direction: column;
            }
        }
        li {
            flex-direction: row;

            a {
                width:600px;
                img {
                    width:600px;
                }
            }
        }

        
        @media (max-width:1440px) {
            max-width: 90%;


         
        }

         
        @media (max-width:1366px) {
            max-width: 90%;


            li a {
                width: 50%;

                img {
                    width: 100%;
                }
            }
        }


        @media (max-width:1024px) {
            max-width: 100%;

            .feedItem {
                margin: 0;
            }


            li a {
                width: 50%;

                img {
                    width: 100%;
                }
            }
        }


        @media (max-width:820px) {
            max-width: 100%;

            .feedItem {
                margin: 0;
            }


            li a {
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }
    }
}

.our-mission {

    .items {

        .item {
            display: flex;
            justify-content: center;


            .img-wrapper {
                width:600px;
                margin: 0;
                img {
                    width: 100%;
                    height:100%;
                    object-fit: cover;
                }
            }


            .content-wrapper {
                background: $bg-dark_second;
                padding:3em;
                width:50%;
            }
        }
    }


    .slider-nav {
        display:flex;
        list-style: none;
        padding: 0;
        margin-top:1em;

     


        .prev-slide,.next-slide {
            margin-right: 1em;
        background: transparent;
        border-radius: 50%;
        border:1px solid $body-colour;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height:40px;
        cursor: pointer;

        &:hover {
            background-color: $body-colour;

            &:before {
                filter: invert(1);
            }
        }


        &:before {
            content:"";
            background-image: url($assets-path + "AL-1.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 16px;
            height:16px;
            display: block;

        }


        

        }

        .prev-slide {
            &:before {
                transform: rotate(180deg);
            }
        }

        
    }


    @media (max-width:820px) {

        .items {
            .item {
                flex-direction: column;

                .img-wrapper {
                    width: 100%;
                }

                .content-wrapper {
                    width: 100%;

                    .button {
                        width:100%;
                    }
                }
            }
        }
    }


}


//Home box 1 - Section 1 - End



//homeFeed - Start

.homeFeedBox2 .feedList .feedItem:nth-child(1)  {
    flex:1
}

.homeFeedBox2,.homeFeedBox1 {
    p {
        display: none;
    }
}

.homeFeed {
    .feedsTitle {
        font-family: $font-family-secondary;
        font-size: $font-size-h2;
      
    }


    .feedList {

        .feedItem {
            border:none;

            .feedItemDetailsWrapper {
                padding: 1rem 0rem;

                .feedTitle {
                    font-size: $font-size-h4;
                    font-family: $font-family-secondary;
                    text-transform: uppercase;
                    // min-height: 50px;

                 
                 
                    a {
                        color: $body-colour;
                        text-decoration-color: transparent;

                        &:hover {
                            text-decoration-color: $brand-secondary;
                        }

                    }
                }

          

                .button {
                    margin-top: 1em;
                }
            }

            .postFooterWrapper {
                display: none !important;
            }
        }
        
    }
}


//homeFeed - End



//Home box 3 - Section 2 col cta - Start

#two_cols_cta_section {

    margin: 2em;

    .items {

        .item {
            display: flex;
            justify-content: center;


            .img-wrapper {
                width:50%;
                margin: 0;
                img {
                    width: 100%;
                    height:100%;
                    object-fit: cover;
                }
            }


            .content-wrapper {
                background: $brand-tertiary;
                padding:3em;
                width:50%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                
                .title {
                    text-transform: uppercase;
                }

                .title_two {
                    text-transform: uppercase;
                    font-size: $font-size-h4;
                }

                .button {
                    width: fit-content;
                }
            }
        }
    }


    @media (max-width:820px) {
        .items {

            .item {
                flex-direction: column;

                .img-wrapper,.content-wrapper {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

}

//Home box 3 - Section 2 col cta - End




// Donation Section - Start

#donation_section {

    background-color: $brand-tertiary;
    border-radius: 10px;
    width: 70%;
    margin: auto;
    margin-top: 6em;
    margin-bottom: 0em;
  padding: 60px ;
  .quickGivingPanel .formQuestion.donationAmount.donationAmountOther label {
    text-transform: uppercase;
  }
  @media (max-width:430px) {
    padding: 25px ;
    width: 90%;
  }


    .title {
        text-transform: uppercase;
        padding: 0;
        // text-align: center;

        @media (max-width:820px) {
                font-size: $font-size-h3;
            }
        
    }


    .quickGivingPanel {
        background: none;
        // text-align: center;
       padding-top:0 ;
       margin: 0;
       padding: 0;


        .selectDonationAmount {
            display: flex;
            align-items: center;
            // justify-content: center;
            margin: 0;

            @media (max-width:820px) {
                flex-direction: column;
            }

            strong.donationAmountFigure {
                font-size: 31px !important;
            }

            .donationAmount  {
                border-radius: 10px;
                border: 1px solid rgba($body-colour, 0.4);
                background:transparent;
                font-family: $font-family-secondary;
                text-transform: uppercase;
                font-size: 31px ;
                margin-right: 0.5em;

                &:hover {
                    background-color: $body-colour;
                    color:$white;
                }


                &.donationSelected {
                    background-color: $body-colour;
                    color:$white;
                }
            }
        }

        .button {
            margin: 0;


            background: $brand-primary;
            border:2px solid $brand-primary;
            padding:25px 40px;

            @media (max-width:820px) {
                // margin: 0;
                margin-top:1em;
                width:100%;
            }
    
            &:hover {
                background: $custom-red;
                border:2px solid $custom-red;
                color:$white;
            }
    


        }
    }
}


// Donation Section - End





//Cta - Start


#story_cta_one,#funraise_cta_one {
    padding: 2em 2em;

    .feedsTitle {
        text-transform: uppercase;
        
    }


    .homeFeed .feedList .feedItem .feedItemDetailsWrapper .feedTitle {
        min-height: 100px;
    }

    .items {
        display: flex;
        justify-content: center;
        align-items: center;


        .content-wrapper {
         .title {
            // width: 65%;
            margin-right: 0.5em;
           
         }
        }

      
    }

    @media (max-width:820px) {
        .items {
            flex-direction: column;

            .content-wrapper {
                text-align: center;
            }

            .cta-wrapper {
                width: 100%;

                a {
                   width: 100%;
                    
                }
            }
        }
    }
}

#funraise_cta_one {


    .items {
        .content-wrapper {
            .title {
                width: 70%;
                margin: 0;
                margin: auto;
            }
        }


        .cta-wrapper {
            a:nth-of-type(1) {
                margin-right: 15px;
            }
        }
    }
@media (max-width:1024px) {
.items {
    .content-wrapper {
        .title {
            font-size: $font-size-h3;

        }

        
    }

    .cta-wrapper {
        display:flex;
    }
}

}


    @media (max-width:820px)  {

        .items {

            .cta-wrapper {
                display: flex;
                flex-direction: column;

                margin-top:2em;

                a {
                    margin-bottom: 10px;
                    
                }
            }
        }

    }
}


//Cta - end


#two_column_img_cta {

padding: 2em 0em;



    .items {
        display: flex;
        justify-content: space-between;

        .item {
            width: 50%;
            position: relative;


            margin:0em 1em;


     

            &:before {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(180deg, rgba(2, 2, 2, 0) 35%, rgba(2, 2, 2, 0.7) 73%);
                position: absolute;
                z-index: 1;
            }
   
            .img-wrapper {
                // width: 700px;
                position: relative;
                height: 400px;
                margin: 0;
                img {
                    width: 100%;
                    height:100%;
                    object-fit: cover;
                }


                .content-wrapper {
                    position: absolute;
                    bottom: 5%;
                    left:50%;
                    transform: translateX(-50%);
                    text-align: center;
                    z-index: 1;

                    .title {
                        color: $white;
                        // text-transform: uppercase;
                    }
                }


            }
        }
    }


    @media (max-width:820px) {
        .items {
            flex-direction: column;

         .item {
            width: 100%;
            margin:0em;
            margin-bottom: 2em;

            padding:0em 2em;


            &:before {
                width:94%;
            }


            .button  {            
                width: 100%;
            }

         
           
         }
        }
    }


    @media (max-width:430px) {
        .items .item {
            padding:0em 1em;
          
        }
    }
}
//News Letter - Start 

.footerBox.NewsletterSign-up{


    background: white;
    text-align: center;

    #mc_embed_signup_scroll {
        max-width: 1240px;
        margin: auto;
    }

    .title {
        font-size: $font-size-h2;
        text-transform: uppercase;
        color: $body-colour;
    }

    .form-wrapper {
        width: 100%;
        max-width: 1240px;
        margin-left: auto;
        margin-right: auto;
        // padding-top: 5rem;
        // padding-bottom: 5rem;
        padding: 2em 0em;
        padding-left: calc(20px - 15px);
        padding-right: calc(20px - 15px);
        position: relative;
        text-align: center;
        padding-top: 4em;
        .form-group {
            display: flex;
            justify-content: left;
    
            .mc-field-group {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-right: 1em;
                color:$body-colour;
                width: 30%;


                @media (max-width:820px) {
                    width:100%;
                }

             
                .asterisk {
                    color: red;
                }
    
                input {
                    border-radius: 5px;
                    background: none;
                    border: 1px solid rgba($body-colour, 0.2);
                    width:100%;
                }
            }
        }

        @media (max-width:820px) {

            .form-group {
                flex-direction: column;

                .mc-field-group {

                    label {
                        font-weight: 600;
        }
                    }
                    width: 100%;;
                }

            
        }

        #mergeRow-gdpr {

                text-align: left;
                margin-top: 1em;
                color: $body-colour;

                input#gdpr_16037 {
                    margin-right: 10px;
                }

        
        }

       
    }
    

    .clear {
        display:flex;
        input#mc-embedded-subscribe{
              
            border: 2px solid #0C0C0C !important;
        }
    }

@media (max-width:430px) {
    padding: 3rem 1rem;
}


}


//News Letter - End 



// Footer - Start

.pageFooter {
    display: block;
    padding-bottom: 0;

    .FooterContent   {
        margin: 0;
        background-color: $bg-dark_second;
     

        .footer-wrapper {
            // padding: 2em 10em;
 


            width: 100%;
            max-width: 1240px;
            margin-left: auto;
            margin-right: auto;
            padding: 2em 0em;
            padding-left: calc(20px - 15px);
            padding-right: calc(20px - 15px);
            position: relative;
            // text-align: center;
            padding-top: 4em;




            .items {

     
                display:flex;
                flex-wrap: wrap;
                justify-content: space-between;


                .item {
                    width: calc(100% / 3 - 2em);
                    margin: 1em;
                    padding: 2em;


                    .heading {
                        font-size: $font-size-h4;
                        text-transform: uppercase;
                    }

                    &.contact-us {

                        .contact-info {

                            .info-address,.info-phone {
                                display: flex;
                                margin-bottom: 10px;
                              
                                svg {
                                    width: 20px;
                                }

                                .address{
                                    margin-left: 10px;

                                }
                                .phone {
                                    margin-left: 8px
                                }
                            }


                        }

                    }


                    &.quick-links {
                        .footerNav {
                            li {
                                padding: 0.4em 0em;
                                a {
                                    color: $body-colour;
                                    text-decoration: none;
                                    font-weight: 700;
                
                                    &:hover {
                                        border-bottom: 1px solid rgba($body-colour, 1);
                                    }
                
                                }
                            }
                        }
                    }


                    &.socials {
                        .socialIcons {
                            .facebook {
                                &:before {
                                    color: $facebook-brand-colour;
                                }
                            }

                            .twitter {
                                &:before {
                                    color: $twitter-brand-colour;
                                }
                            }

                            .instagram {
                                &:before {
                                    color: $instagram-brand-colour;
                                }
                            }

                            .youtube {
                                &:before {
                                    color: $youtube-brand-colour;
                                }
                            }


                            li {
                                a {
                                    &:hover {
                                        border-bottom: 1px solid #0c0c0c;
                                    }
                                }
                            }
                        }


                        .heading.acredation {
                            margin-top: 1em;
                            margin-bottom: 0;
                        }

                        .acredation-wrapper {
                            display: flex;

                            figure {

                                width:120px;
                                margin-bottom: 0;
                                margin-left: 0;
                                margin-top: 0;
                                
                                img {
                                    width: 100%;
                                    height:100%;
                                    object-fit:contain;
                                }
                            }
                        }
                    }



        
                }

            }


            .copyright-wrapper {
                .copyright {
                    font-size: 14px;
                }
            }
        }

    }


    @media (max-width: 1024px) {

        .FooterContent  {
            .footer-wrapper {
                padding: 2em 4em;
             
            }
        }

    }

    
    @media (max-width: 430px) {
        
        .FooterContent  {
            .footer-wrapper {
                padding: 2em 2em;
                .items .item {
                    width: calc(100% / 1 - 1em);
                    padding: 0;
                }
            }
        }
    }
}

// Footer - End


//Listing Page -Start

.listing   {

.carousel {
    display: none;
}

    .headerWrapper  {
        position: relative;


        &.currentProjectsHeader {
            &:before {
                content:none;
            }
        }
        &:before {
            content:"";
            background: linear-gradient(to bottom, #93EBEA, #FEDF77);
            display: block;
            // height: 100%;
            width: 100vw;
            position: absolute;
            bottom:0;
            left:50%;
            transform: translateX(-50%);
            height: calc(100% + 118px + 1em);
            z-index: -1;


            @media (max-width:430px) {
                height: calc(100% + 118px + 3em);   
            }
    
    
    
        }


        .headerText {
            padding-bottom: 2em;

            .breadcrumbWrapper {

                .breadcrumbItem {
                    font-weight: 700;

                    &:before {
                        content: "/";
                        font-weight: 700;
                        font-family: $font-family-base;
                    }
                }

                .breadcrumbLink {
                    a {
                        color:rgba($body-colour, 1);
                        font-weight: 700;
                    }
                }
                
            }

            .title {
                font-family: $font-family-secondary;
                text-transform: uppercase;
            }
        }
    }


    .contentBlockWrapper {

    .totaliserPanel {
        opacity: 1 !important;
      
    }

    .raisedWrapper .totaliserFigure {
        color: $body-colour !important;
    }
       
        .listedPost   {
            border: none !important;

            .listedPostText {
                font-size: $font-size-h4;
                font-family: $font-family-secondary;
                text-transform: uppercase;
                // min-height: 50px;

        
             
                h2  {
                    min-height: 70px;
                    a {
                        color: $body-colour !important;
                        text-decoration-color: transparent;
                       
    
                        &:hover {
                            color: $body-colour;
                            -webkit-text-decoration-color:$brand-secondary;
                            text-decoration-color: $brand-secondary;
                   
                        }
    
                    }
                }

                h3 {
                    color: $body-colour !important;
                }
                

              
            }
        }
       .listedItem  {
            border: none !important;

            


            .listedPostText {
                font-size: $font-size-h4;
                font-family: $font-family-secondary;
                text-transform: uppercase;
                // min-height: 50px;

             
             
                h2  {
                    min-height: 70px;
                    a {
                        color: $body-colour;
                       
    
                        &:hover {
                            color: $body-colour;
                            text-decoration-color: $brand-secondary;
                        }
    
                    }
                }
                

                .postSummary {
                    display: none;
                }
            }
        }
    }

}


//Listing Page - End


//Post Page - Start
.blogsPostBody,.newsPostBody,.eventsPostBody,.pagesPostBody,.appeals-Post, .page-has-banner    {


    position: relative;
    &:before {
        content:"";
        background: linear-gradient(to bottom, #93EBEA, #FEDF77);
        display: block;
        // height: 100%;
        width: 100vw;
        position: absolute;
        top:0;
        left:50%;
        transform: translateX(-50%);
        height: calc(7% + 118px + 1em);
        z-index: -1;



        @media (max-width:1024px) {

            height: calc(5% + 118px + 1em);

        }


        @media (max-width:430px) {
            height: calc(3% + 118px + 1em);
        }

    }

    .quickGivingPanel {
      
        // text-align: center;
       padding-top:0 ;
       margin: 0;
       padding: 0;

       
    background-color: $brand-tertiary;
    border-radius: 10px;
    width: 100%;
    margin: auto;
    margin-top: 6em;
    margin-bottom: 0em;
  padding: 60px ;


        .selectDonationAmount {
            display: flex;
            align-items: center;
            // justify-content: center;
            margin: 0;

            @media (max-width:820px) {
                flex-direction: column;
            }

            strong.donationAmountFigure {
                font-size: 31px !important;
            }

            .donationAmount  {
                border-radius: 10px;
                border: 1px solid rgba($body-colour, 0.4);
                background:transparent;
                font-family: $font-family-secondary;
                text-transform: uppercase;
                font-size: 31px ;
                margin-right: 0.5em;

                &:hover {
                    background-color: $body-colour;
                    color:$white;
                }


                &.donationSelected {
                    background-color: $body-colour;
                    color:$white;
                }
            }
        }

        .button {
            margin: 0;


            background: $brand-primary;
            border:2px solid $brand-primary;
            padding:25px 40px;

            @media (max-width:820px) {
                // margin: 0;
                margin-top:1em;
                width:100%;
            }
    
            &:hover {
                background: $custom-red;
                border:2px solid $custom-red;
                color:$white;
            }
    


        }
    }

    blockquote {
        background: #0C0C0C;
    }
    .carousel {
        max-width: 1240px;
    }

    .post .contentBlockWrapper, .post .headerText .title {
        max-width: 1240px;
    }

    aside.postAside.postFeedWrapper {
        display: none;
    }


    .blogDetails {
        max-width: calc(1280px - 40px);
    }
      
    
        .headerWrapper  {
     margin-top: 3em;
    
            .headerText {
                // padding-bottom: 2em;
    
                .breadcrumbWrapper {

                    max-width: 1240px;
    
                    .breadcrumbItem {
                        font-weight: 700;
    
                        &:before {
                            content: "/";
                            font-weight: 700;
                            font-family: $font-family-base;
                        }
                    }
    
                    .breadcrumbLink {
                        a {
                            color:rgba($body-colour, 1);
                            font-weight: 700;
                        }
                    }
                    
                }

                .title {
                    font-family: $font-family-secondary;
                    text-transform: uppercase;
                }
            }
        }
    
    
     
    
    }


    .post {
        .postFooterWrapper {
            .facebook {
                &:before {
                    color:#3b5998;;
                }
            }

            .twitter {
                &:before {
                    color:#1da1f2;;
                }
            }
        }
    }
    

//Post Page - End

 

//Blockquote


blockquote {
position: relative;

    &:after {
        content:"";        
        position: absolute;
        bottom: -15px;
        left: 10px;
        width: 47px;
        height: 32px;
        background-image: url(../assets/speech.svg);
        background-size: contain;
        background-repeat: no-repeat;

    }


    > *:first-child:before,
    > *:last-child:after {
      content: "\"";
      padding: 0em 3px;
      color: $brand-primary;
    }
}


//acorrdion 

.accordianHeaderText.faqQuestionText {
    a {
        color:#0C0C0C;
    }
}



.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) [class^="listed"][class$="Text"]>*:last-child {
    font-family:$font-family-base;
    text-transform: initial;

}

.pageWrapper.appealListingPage {
    .donorsListWrapper {
        display:none;
    }

    .headerWrapper.fundraisersHeader {
        &:before{
            content:none;
        }
    }
}



//Giftcard page

.shopListingPage {
    .departmentsContent {
        a {
            color:#0C0C0C;
        }
    }

    .contentBlock.productList {
        a {
            color:#0C0C0C;
        } 
    }

    .productPrice {
        color:#0C0C0C;
    }
}




// task - hide duplicate buttons when hamburger menu drops
@media screen and (min-width: 399px) {

    .menuMain .mainCallToAction {
        display: none;
    }
}


// task - margin nav is to small
#carouselSlides li.carouselSlide.post08cf8d4b9f214ba0b7c48bffbb472fee {
    margin-top: 75px!important;
}


// Task - 3 lines subheading
.carouselSlideSummary {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

// Task - hover on submenu
ul.subMenu li:hover>a  {
    border:1px solid $body-colour;
  }


// Task - padding image on church partnership
img[src="/GetImage.aspx?IDMF=069dbfbd-b870-4161-9ec2-5bb0ccca79cf&w=800&h=450&src=mc"] {
    padding: 10px;
  }



  //Feed Item on hover 


//   .mainCallToAction .socialIcons {
//     display:none;
//   }


//slider Nav

.slider-nav-custom {
    position: absolute;

    display:flex;
    list-style: none;
    padding: 0;
    margin-top:1em;

    width: 85%;
    max-width: 1600px;
    bottom: 150px;
    left: 44%;


@media (max-width:1440px) {
    bottom: 130px;
    left: 50.5%;
}


@media (max-width:1366px) {
    bottom: 109px;
    left: 52.5%;
}


@media (max-width:1024px) {
    bottom: 50px;
    left: 53.5%;

}

@media (max-width:820px) {
  
    left: 52%;

}


@media (max-width:430px) {
  
    left: 8%;

}


 


    .prev-slide,.next-slide {
        margin-right: 1em;
    background: transparent;
    border-radius: 50%;
    border:1px solid $body-colour;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height:40px;
    cursor: pointer;

    &:hover {
        background-color: $body-colour;

        &:before {
            filter: invert(1);
        }
    }


    &:before {
        content:"";
        background-image: url($assets-path + "AL-1.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 16px;
        height:16px;
        display: block;

    }


    

    }

    .prev-slide {
        &:before {
            transform: rotate(180deg);
        }
    }

    
}
