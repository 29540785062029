$platform-name: 'barnabusmanchester';
$assets-path: "../assets/" ;




//Fonts

@font-face {
    font-family: "Ambit";
    src: url($assets-path + "Ambit-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: "Ambit";
    src: url($assets-path + "Ambit-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "VTC Martin King";
    src: url($assets-path + "VTCMartin-King.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}





// Colours
$brand-primary: #D33F41;
$brand-secondary: #93EBEA;
$brand-tertiary: #FEDF77;
$brand-quarternary: #0C0C0C;

$bg-color: #fff;
$bg-dark: #0C0C0C;
$bg-light:#FCEDB5;

$bg-dark_second:#FFC38C;
$body-colour: #0C0C0C;
$border-colour: #0c0c0c;
$custom-red:#A52C2E; 


// Greyscale
$black: #000;
$white: #fff;



// Links
$link-colour: #002BFF;


// Logo
$logo-width: 231px;
$logo-height: 80px;
$logo-mobile-width: 144px;
$logo-mobile-height: 50px;


// Typography
$font-family-base: "Ambit", sans-serif;
$font-family-secondary: "VTC Martin King", sans-serif;
$bold:700;

$font-size-base: 16px;
$font-size-large: 20px;
$font-size-small: 14px;

$font-size-h1: 49px;
$font-size-h2: 39px;
$font-size-h3: 31px;
$font-size-h4: 25px;
$font-size-h5: 20px;
$font-size-h6: 16px;

$font-weight-normal: normal;
$font-weight-bold: bold;
$line-height-base: 1.4;

$headings-line-height: 1.2;
$headings-colour: $body-colour;

// Layout
$container-max-width: 1240px;



// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 0.3s;
$transition-curve: ease;



//Buttons

// Buttons
$btn-background-colour: $brand-quarternary;
$btn-text-colour: $white;
$btn-border-radius: 40px;
$btn-padding-x: 30px;
$btn-padding-y: 15px;
$btn-font-weight: $font-weight-bold;
$btn-font-size: $font-size-base;
$btn-line-height: 1.25;


// Search
$header-search-margin-right: 15px;

// Search - input
$header-search-input-max-width: 280px;
$header-search-input-margin-right: 0;
$header-search-input-padding: 9.5px 30px;
$header-search-input-placeholder-colour: $body-colour;
$header-search-input-border-radius: 0;
$header-search-input-border-width: 0; // t-r-b-l
$header-search-input-background-colour: transparent;
$header-search-input-colour: $body-colour;
$header-search-input-focus-background-colour: transparent;
$header-search-input-focus-colour: $body-colour;



// Search - button
$header-search-button-width: 24px;
$header-search-button-height: 24px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: 18px;
$header-search-button-icon-colour: $black;
$header-search-button-border-radius: 0;


// Carousel
$carousel-border-radius: 0;
$carousel-details-style: adjacent; // overlay, adjacent or below
$carousel-details-background-colour: transparent;
$carousel-details-max-width: 100%;
// $carousel-details-padding: 120px 50px 240px 20px;
$carousel-details-padding: 2em 3em 2em 1em ;
$carousel-details-border-radius: 0;
$carousel-heading-colour: $body-colour;
$carousel-heading-font-size: $font-size-h1;
$carousel-summary-colour: $body-colour;
$carousel-summary-font-size: $font-size-base;
$carousel-read-more-colour: $body-colour;
$carousel-read-more-background-colour: $white;

// Carousel controls (left-right buttons)
$carousel-controls-button-size: 40px;
$carousel-controls-button-gap: 40px;
$carousel-controls-button-background-colour: transparent;
$carousel-controls-button-border-radius: 50%;
$carousel-controls-position-y: bottom; // top, center, bottom
$carousel-controls-position-x: right; // split, left or right
$carousel-controls-icon-colour: $black;


// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: transparent;
$carousel-mobile-heading-colour: $carousel-heading-colour;
$carousel-mobile-summary-colour: $carousel-summary-colour;

//Card

$card-image-background-colour: $brand-tertiary;

// Slick
$slick-enabled: true;



// Nav normal

$nav-normal-margin-top: 0 ;
$nav-normal-margin-bottom: 0;






$footer-boxes-count: 3;

// Social icons (https://github.com/RaisingITDev/fresco/wiki/social-icons)
// Social network colours - use these by changing $social-icons-use-brand-colours to true
$twitter-brand-colour: #1da1f2 !default;
$facebook-brand-colour: #3b5998 !default;
$youtube-brand-colour: #ff0000 !default;
$instagram-brand-colour: #833ab4 !default;
$linkedin-brand-colour: #0077b5 !default;
$pinterest-brand-colour: #bd081c !default;
$tripadvisor-brand-colour: #00af87 !default;
$vimeo-brand-colour: #1ab7ea !default;

$social-icons-background-colour: transparent;
// $social-icons-colour: $white;
$social-icons-hover-colour: $body-colour; // A colour, or 'brand'
$social-icons-width: 40px !default;
$social-icons-height: 40px !default;
$social-icons-font-size: 1.2rem !default;
$social-icons-gap: 5px !default;
$social-icons-border-radius:  none;

